.contact {
  @extend .center;
  @extend .skin-white;
  @extend .ba-darker;
  @extend .br-normal;
  @extend .o-hidden;
  @extend .w-100;

  height: 100%;

  .contact-image {
    height: 12em;
    background-position: center;
    background-size: cover;
  }

  .contact-body {
    @extend .pa-m;
    @extend .ta-center;
    @extend .ta-left-xl;

    h5 {
      @extend .fw-normal;
    }
  }
}