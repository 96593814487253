.split-bg-dark {
  background-image: linear-gradient($bg-dark, $bg-dark-lighter);
  background-repeat: no-repeat;
}
.split-bg-dark-md {
  @extend .split-bg-dark;
  background-size: 100% 10rem;
}

.split-bg-dark-lg {
  @extend .split-bg-dark;
  background-size: 100% 16rem;
}
