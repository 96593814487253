@mixin styled-select($primary-color: white, $border: false) {
  appearance: none;

  width: auto;
  padding: 5px;
  padding-right: 2rem;

  @if $border {
    border: 1px solid $primary-color;
  } @else {
    border: none;
  }

  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='#{$primary-color}' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;

  color: $primary-color;

  option {
    color: black; // Required for Edge..
  }
}
