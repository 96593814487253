/* Key Color */
.c-brand          { color: $brand; }
.c-brand-lighter  { color: $brand-lighter; }
.c-brand-darker   { color: $brand-darker; }

.c-action         { color: $action; }
.c-action-lighter { color: $action-lighter; }
.c-action-darker  { color: $action-darker; }

.c-lightest       { color: rgba(255, 255, 255, 1); }
.c-lighter        { color: rgba(255, 255, 255, .8); }
.c-light          { color: rgba(255, 255, 255, .6); }

.c-dark           { color: rgba(0, 0, 0, .4); }
.c-darker         { color: rgba(0, 0, 0, .6); }
.c-darkest        { color: rgba(0, 0, 0, .8); }

.c-white          { color: white; }

.c-banner         { color: $banner; }
