.slide-in-left {
    animation: slideInLeft .5s ease;
}

.slide-in-right {
    animation: slideInRight .5s ease;
}

.slide-out-left {
    animation: slideOutLeft .5s ease;
}

.slide-out-right {
    animation: slideOutRight .5s ease;
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes slideOutRight {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}