ion-icon {
  --ionicon-stroke-width: 1.5rem;

  @extend .lh-title;
  vertical-align: middle;

  &.icon-xl { font-size: 5em; }
  &.icon-l  { font-size: 4em; }
  &.icon-m  { font-size: 3.3em; }
  &.icon-base  { font-size: 2em; }
  &.icon-s  { font-size: 1.5em; }
}

@media #{$breakpoint-md} {
  ion-icon {
    &.icon-m-md  { font-size: 3.3em; }
  }
}