.story-link {
  position: relative;
  @extend .skin-white;
  @extend .pa-l;

  @extend .ta-center;

  @extend .ba-darker;
  @extend .br-normal;

  @extend .o-hidden;
  cursor: pointer;

  .story-logo {
    height: 2em;
    @extend .mb-m;
    @extend .mt-xs;
    //Apparently needed to stop these logos from flickering.
    //http://stackoverflow.com/questions/2946748/iphone-webkit-css-animations-cause-flicker
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
  }

  .grid {
    height: 4em;
  }

  .story-title {
    @extend .mw-measure-tiny;
    @extend .fs-copy;
    @extend .o-100;
    @extend .lh-title; 
    transition-property: opacity;
    transition-duration: $ad-fast;
    transition-timing-function: ease-in-out;
  }

  .story-button {
    position: absolute;
    top: 52.5%;
    left: 50%;

    @extend .fs-label;
    @extend .o-0;

    transform: translateY(100%) translateX(-50%);

    transition-property: opacity, transform;
    transition-duration: $ad-fast;
    transition-timing-function: ease-in-out;

  }

  &:hover {
    @extend .ba-brand;

    .story-title {
      @extend .o-0;
    }

    .story-button {
      @extend .o-100;
      transform: translateY(0%) translateX(-50%);
    }
  }
}
