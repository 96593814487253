table {
  font-size: 90%;
  th {
    border-bottom: 0.1rem solid grey;
  }
  th, td {
    text-align: left;
    vertical-align: middle;
    min-width: 10rem;
    padding: 1rem 0.5rem 1rem 1rem;
  }
}
