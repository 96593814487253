.article {
  @extend .split-bg-dark-lg;
  @extend .fw-light;

  h1,h2,h3,h4,h5,h6{
    @extend .c-darkest;
    @extend .mb-s;
  }
  h1 {
    @extend .fs-h3;
  }
  h2 {
    @extend .fs-h4;
  }
  h3 {
    @extend .fs-h5;
  }
  h4 {
    @extend .fs-h6;
  }
  h5 {
    @extend .fs-h6;
  }
  h6 {
    @extend .fs-copy;
    @extend .fw-bold;
  }

  p:not(.quote) {
    @extend .mb-l;
  }

  ul, ol {
    @extend .ml-l;
    @extend .mb-l;

    li {
      @extend .pl-s;
    }
  }
  ul.compact, ol.compact {
    li {
      @extend .mb-s;
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  a[href]:not(.btn) {
    @extend .link-brand;
  }

  img {
    width: 120%;
    margin-left: -10%;
    @extend .br-normal;
  }
  img:first-child {
    @extend .mb-l;
  }
  img:not(:first-child) {
    @extend .mb-xl;
  }

  blockquote {
    @extend .center;
    @extend .mw-measure-narrow;
    @extend .mv-l;
    @extend .ph-m;
    @extend .lh-title;

    @extend .c-brand;
    @extend .fs-h4;
    @extend .ta-center;
    quotes: "\201C""\201D""\2018""\2019";

    &:before, &:after {
      font-family: serif;
      @extend .fs-h1;
    }
    &:before {  content: open-quote; }
    &:after {   content: close-quote; }
  }

  textarea {
    @extend .w-100;
    min-height: 5em;
    @extend .pa-m;

    @extend .br-normal;
    @extend .ba-darker;

    font-family: monospace;
    @extend .fs-copy;

    resize: none;
  }

}
