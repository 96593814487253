.anim {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}
.ad-1   { animation-delay: 150ms; }
.ad-2   { animation-delay: 300ms; }
.ad-3   { animation-delay: 450ms; }
.ad-4   { animation-delay: 600ms; }
.ad-5   { animation-delay: 750ms; }
.ad-6   { animation-delay: 900ms; }
.ad-7   { animation-delay: 1050ms; }
.ad-8   { animation-delay: 1200ms; }
.ad-9   { animation-delay: 1350ms; }
.ad-10  { animation-delay: 1500ms; }
.ad-11  { animation-delay: 1650ms; }
.ad-12  { animation-delay: 1800ms; }

.a-fade {
  @extend .anim;
  animation-name: fade;
}

.a-fade-up {
  @extend .anim;
  animation-name: fade-up;
}

.a-fade-down {
  @extend .anim;
  animation-name: fade-down;
}

@keyframes fade {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down {
  from {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
