.btn {
  display: inline-block;
  @extend .br-normal;
  @extend .clickable;

  height: 2.2em;
  @extend .ph-m;
  line-height: 2.2em;

  white-space: nowrap;
}

.btn-brand {
  color: white !important;
  @extend .skin-brand;
  @extend .ba-darker;

  &:hover {
    @extend .bg-brand-lighter;
  }
  &:active {
    @extend .c-lighter;
    @extend .bg-brand-darker;
  }
}

.btn-banner {
  @extend .bg-banner-full;
  @extend .c-lightest;
  @extend .ba-none;

  &:hover {
    @extend .bg-banner-full-lighter;
  }
  &:active {
    @extend .bg-banner-full-lighter;
  }
}

.btn-white-ghost {
  @extend .c-lightest;
  @extend .ba-lightest;

  &:hover {
    @extend .c-lighter;
    @extend .ba-lighter;
  }
  &:active {
    @extend .c-light;
  }
}

.btn-brand-ghost {
  @extend .c-brand;
  @extend .bg-transparent;
  border: 0.1rem solid $brand;

  &:hover {
    @extend .c-brand-lighter;
    border-color: $brand-lighter;
  }
  &:active {
    @extend .c-brand-darker;
    border-color: $brand-darker;
  }
}

.btn-action {
  @extend .c-lightest;
  @extend .fw-bolder;
  @extend .ba-darker;
  @extend .bg-action;
  @extend .ph-l;
  @extend .pv-s;

  &:hover {
    @extend .bg-action-lighter;
  }
  &:active {
    @extend .c-lighter;
    @extend .bg-action-darker;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.clickable {
  cursor: pointer;
}
