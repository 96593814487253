.job-offer-link {
  position: relative;
  @extend .skin-white;

  @extend .ta-center;

  @extend .ba-darker;
  @extend .br-normal;

  @extend .o-hidden;
  cursor: pointer;

  .job-offer-logo {
    @extend .mw-100;

    transition-property: filter;
    transition-duration: $ad-fast;
    transition-timing-function: ease-in-out;
  }

  .job-offer-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;

    @extend .fs-label;
    @extend .o-0;

    transform: translateY(100%) translateX(-50%);

    transition-property: opacity, transform;
    transition-duration: $ad-fast;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    @extend .ba-brand;

    .job-offer-logo {
      filter: blur(0.5rem);
    }

    .job-offer-button {
      @extend .o-100;
      transform: translateY(0%) translateX(-50%);
    }
  }
}
