.bs-light       { box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, .2 ); }
.bs-medium      { box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, .2 ); }
.bs-heavy       { box-shadow: 0 0 0.8rem 0 rgba(0, 0, 0, .2 ); }

.bs-active-item { box-shadow: inset 0 -0.2rem $brand; }

.bs-none        { box-shadow: none; }


.bsbs {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 999;
  box-shadow: inset 0 0 4rem rgba(0,0,0,0.05);
}
