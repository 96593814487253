.security-row {
  @extend .skin-white;
  @extend .mv-m;
  @extend .pa-l;
  @extend .br-normal;
  @extend .ba-darker;

  display: flex;
  flex-wrap: wrap;
  list-style: none;

  .security-img {
    flex: 0 0 100%;

    img {
      max-width: 7em;
      max-height: 7em;
    }
  }

  @media #{$breakpoint-lg} {
    .security-img {
      flex: 0 0 calc(100% / 8);

     }
  }

  .security-header {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;

    h5 {
      display: flex;
      align-items: center;
    }
  }

  @media #{$breakpoint-lg} {
    .security-header {
      padding-left: 2em;
      flex: 0 0 (calc(100% / 8) * 7);
      justify-content: flex-start;
      align-content: center;
    }
  }

  .security-content {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex: 0 0 100%;
    flex-direction: row-reverse;

    .security-text {
      flex: 0 0 100%;

      p {
        @extend .ta-left;
        @extend .fs-label;
      }

      ul {
        @extend .list-outside;

        li {
          @extend .ta-left;
          @extend .fs-label;

          padding-left: 3rem;

          ion-icon {
            position: absolute;
            margin-left: -3.5rem;
          }
        }

      }
    }

    @media #{$breakpoint-lg} {
      .security-text {
        padding-left: 2em;
        flex: 0 0 (calc(100% / 8) * 7);
      }
    }

  }
}
