.main-navigation {
	position: sticky;
	top: 0;
	z-index: 10000;
	a {
		@extend .fs-label;
	}
	.logo {
		height: 3.1em;
	}
}
