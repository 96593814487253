/* Key Color */
$brand:           #1dc4c2;
$brand-lighter:   lighten($brand, 7%);
$brand-darker: 	  desaturate(darken($brand, 12%), 20%);
$action:          #ff9000;
$action-lighter:  lighten($action, 7%);
$action-darker:   desaturate(darken($action, 12%), 20%);
$banner:          #6d28d9;
$banner-lighter:  lighten($banner, 7%);
$banner-darker:   desaturate(darken($banner, 12%), 20%);

/* Background Colors */
$bg-dark: 		    #232a34;
$bg-dark-lighter:	#343d4c;
$bg-dark-darker:	#191E25;
$bg-banner:         #cab1ff;
$bg-banner-lighter: lighten($bg-banner, 7%);
$bg-banner-darker: 	desaturate(darken($bg-banner, 12%), 20%);

/* animation/transition duration */
$ad-fast:   150ms;
$ad-medium: 225ms;
$ad-slow:   300ms;
