@function power($number, $power) {
    $exponent: 1;
    @for $i from 1 through $power {
      $exponent: $exponent * $number;
    }
    @return $exponent;
}

$base-sm: 0.8em;
$base-md: 0.9em;
$base-lg: 0.9em;

$ratio-sm: 1.15;
$ratio-md: 1.15;
$ratio-lg: 1.15;

.fs-h1    { font-size: $base-sm * power($ratio-sm, 7); }
.fs-h2    { font-size: $base-sm * power($ratio-sm, 6); }
.fs-h3    { font-size: $base-sm * power($ratio-sm, 5); }
.fs-h4    { font-size: $base-sm * power($ratio-sm, 4); }
.fs-h5    { font-size: $base-sm * power($ratio-sm, 3); }
.fs-h6    { font-size: $base-sm * power($ratio-sm, 2); }
.fs-copy  { font-size: $base-sm * power($ratio-sm, 1); }
.fs-label { font-size: $base-sm; }

@media #{$breakpoint-md} {
  .fs-h1    { font-size: $base-md * power($ratio-md, 7); }
  .fs-h2    { font-size: $base-md * power($ratio-md, 6); }
  .fs-h3    { font-size: $base-md * power($ratio-md, 5); }
  .fs-h4    { font-size: $base-md * power($ratio-md, 4); }
  .fs-h5    { font-size: $base-md * power($ratio-md, 3); }
  .fs-h6    { font-size: $base-md * power($ratio-md, 2); }
  .fs-copy  { font-size: $base-md * power($ratio-md, 1); }
  .fs-label { font-size: $base-md; }
}

@media #{$breakpoint-lg} {
  .fs-h1    { font-size: $base-lg * power($ratio-lg, 7); }
  .fs-h2    { font-size: $base-lg * power($ratio-lg, 6); }
  .fs-h3    { font-size: $base-lg * power($ratio-lg, 5); }
  .fs-h4    { font-size: $base-lg * power($ratio-lg, 4); }
  .fs-h5    { font-size: $base-lg * power($ratio-lg, 3); }
  .fs-h6    { font-size: $base-lg * power($ratio-lg, 2); }
  .fs-copy  { font-size: $base-lg * power($ratio-lg, 1); }
  .fs-label { font-size: $base-lg; }
}

// Tailwind
.text-xs   { font-size: 0.75rem; line-height: 1rem; }
.text-sm	 { font-size: 0.875rem; line-height: 1.25rem; }
.text-base { font-size: 1rem; line-height: 1.5rem; }
.text-lg	 { font-size: 1.125rem; line-height: 1.75rem; }
