.d-none         { display: none; }
.d-inline       { display: inline; }
.d-inline-block { display: inline-block; }
.d-block        { display: block; }
.d-flex         { display: flex; }
.d-inherit      { display: inherit; }
.d-grid         { display: grid; }

@media #{$breakpoint-md} {
  .d-none-md         { display: none; }
  .d-inline-md       { display: inline; }
  .d-inline-block-md { display: inline-block; }
  .d-block-md        { display: block; }
  .d-flex-md         { display: flex; }
  .d-inherit-md      { display: inherit; }
  .d-grid-md         { display: grid; }
}

@media #{$breakpoint-lg} {
  .d-none-lg         { display: none; }
  .d-inline-lg       { display: inline; }
  .d-inline-block-lg { display: inline-block; }
  .d-block-lg        { display: block; }
  .d-flex-lg         { display: flex; }
  .d-inherit-lg      { display: inherit; }
  .d-grid-lg         { display: grid; }
}

.flex-wrap { flex-wrap: wrap; }
.flex-shrink-0 { flex-shrink: 0; }

.items-start {align-items: flex-start;}
.items-end {align-items: flex-end;}
.items-center {align-items: center;}
.items-baseline {align-items: baseline;}
.items-stretch {align-items: stretch;}

.justify-center { justify-content: center;}
.justify-left { justify-content: start;}
.justify-right { justify-content: end;}
.justify-evenly { justify-content: space-evenly;}

.grid-rows-0 { grid-template-rows: 0fr; }
.grid-rows-1 { grid-template-rows: 1fr; }