.ta-left      { text-align: left; }
.ta-right     { text-align: right; }
.ta-center    { text-align: center; }

.align-middle { vertical-align: middle; }

@media #{$breakpoint-md} {
  .ta-left-md    { text-align: left; }
  .ta-right-md   { text-align: right; }
  .ta-center-m   { text-align: center; }
}

@media #{$breakpoint-lg} {
  .ta-left-l    { text-align: left; }
  .ta-right-l   { text-align: right; }
  .ta-center-l  { text-align: center; }
}

@media #{$breakpoint-xl} {
  .ta-left-xl    { text-align: left; }
  .ta-right-xl   { text-align: right; }
  .ta-center-xl  { text-align: center; }
}
