.bg-white               { background-color: white; }

.bg-grey                { background-color: #F8F8FA; }
.bg-grey-lighter        { background-color: #FCFCFF; }
.bg-grey-darker         { background-color: #F0F0FE; }
.bg-grey-brand          { background-color: #EDF8F9; }

.bg-dark                { background-color: #232a34; }
.bg-dark-lighter        { background-color: #343d4c; }
.bg-dark-darker         { background-color: #191E25; }

.bg-brand               { background-color: $brand; }
.bg-brand-lighter       { background-color: $brand-lighter; }
.bg-brand-darker        { background-color: $brand-darker; }

.bg-action              { background-color: $action; }
.bg-action-lighter      { background-color: $action-lighter; }
.bg-action-darker       { background-color: $action-darker; }

.bg-banner              { background-color: $bg-banner; }
.bg-banner-lighter      { background-color: $bg-banner-lighter; }
.bg-banner-darker       { background-color: $bg-banner-darker; }

.bg-banner-full         { background-color: $banner; }
.bg-banner-full-lighter { background-color: $banner-lighter; }
.bg-banner-full-darker  { background-color: $banner-darker; }

.bg-transparent         { background-color: transparent; }
