.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.left-1\/2 {
  left: 50%;
}

.sm\:left-auto {
  @media (min-width: 640px) {
    left: auto;
  }
}

.top-12 {
  top: 3rem;
}

.sm\:top-0 {
  @media (min-width: 640px) {
    top: 0;
  }
}

.-translate-x-1\/2 {
  transform: translateX(-50%);
}

.sm\:translate-x-0 {
  @media (min-width: 640px) {
    transform: translateX(0);
  }
}