.screenshot-iphone-full {
  position: relative;
  font-size: 0;
  filter: drop-shadow(0 0 2rem rgba(0,0,0,.1));

  .shell {
    @extend .mw-100;
  }

  .screenshot {
    @extend .mw-100;
    position: absolute;
    top: 12.6%;
    left: 6.8156%;
    width: 86.1575%;
  }
}

.screenshot-iphone-top {
  position: relative;
  font-size: 0;
  filter: drop-shadow(0 0 2rem rgba(0,0,0,.1));

  .shell {
    @extend .mw-100;
  }

  .screenshot {
    @extend .mw-100;
    position: absolute;
    top: 24.96%;
    left: 6.8156%;
    width: 86.1575%;
  }
}

.screenshot-ipad {
  position: relative;
  font-size: 0;
  filter: drop-shadow(0 0 2rem rgba(0,0,0,.1));

  .shell {
    @extend .mw-100;
  }

  .screenshot {
    @extend .mw-100;
    position: absolute;
    top: 9%;
    left: 6.25%;
    width: 87.3%;
  }
}

.screenshot-browser {
  position: relative;
  font-size: 0;
  filter: drop-shadow(0 0 2rem rgba(0,0,0,0.15));

  .shell {
    @extend .mw-100;
    border-top-left-radius: $br-normal;
    border-top-right-radius: $br-normal;
    @extend .bt-darkest;
    @extend .bl-darkest;
    @extend .br-darkest;
  }

  .screenshot {
    @extend .mw-100;
    @extend .bl-darkest;
    @extend .br-darkest;
  }
}

.screenshot-only {
  position: relative;

  .screenshot {
    @extend .w-100;
    @extend .mb-l;
  }
}