.background-error {
  height: 654px; // Background image height
  background-image: url('/assets/img/background-error-small.png');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #E6F4F2;

  .container-1024 {
    padding-top: 240px;
  }

  // Ensure text and button are still visible on mobile in landscape mode
  @media (max-height: 550px) {
    .container-1024 {
      padding-top: 40px;
    }
  }
}

@media (min-width: 1280px) {
  .background-error {
    height: 782px; // Background image height
    background-image: url('/assets/img/background-error-large.png');
  }
}
