$bc-dark:     rgba(0,0,0,.05);
$bc-darker:   rgba(0,0,0,.10);
$bc-darkest:  rgba(0,0,0,.15);
$bc-light:    rgba(255,255,255,.25);
$bc-lighter:  rgba(255,255,255,.5);
$bc-lightest: rgba(255,255,255,1);

.border { border-width: 1px; }
.border-b { border-bottom-width: 1px; }
.border-solid {border-style: solid; }
.border-dotted {border-style: dotted; }
.border-darkest { border-color: $bc-darkest; }

.ba-brand     { border: 0.1rem solid $brand; }
.ba-dark      { border: 0.1rem solid $bc-dark; }
.ba-darker    { border: 0.1rem solid $bc-darker; }
.ba-darkest   { border: 0.1rem solid $bc-darkest; }
.ba-light     { border: 0.1rem solid $bc-light; }
.ba-lighter   { border: 0.1rem solid $bc-lighter; }
.ba-lightest  { border: 0.1rem solid $bc-lightest; }
.ba-none      { border: none; }

.bt-dark      { border-top: 0.1rem solid $bc-dark; }
.bt-darker    { border-top: 0.1rem solid $bc-darker; }
.bt-darkest   { border-top: 0.1rem solid $bc-darkest; }
.bt-light     { border-top: 0.1rem solid $bc-light; }
.bt-lighter   { border-top: 0.1rem solid $bc-lighter; }
.bt-lightest  { border-top: 0.1rem solid $bc-lightest; }
.bt-none      { border-top: none; }

.br-dark      { border-right: 0.1rem solid $bc-dark; }
.br-darker    { border-right: 0.1rem solid $bc-darker; }
.br-darkest   { border-right: 0.1rem solid $bc-darkest; }
.br-light     { border-right: 0.1rem solid $bc-light; }
.br-lighter   { border-right: 0.1rem solid $bc-lighter; }
.br-lightest  { border-right: 0.1rem solid $bc-lightest; }
.br-none      { border-right: none; }

.bb-dark      { border-bottom: 0.1rem solid $bc-dark; }
.bb-darker    { border-bottom: 0.1rem solid $bc-darker; }
.bb-darkest   { border-bottom: 0.1rem solid $bc-darkest; }
.bb-light     { border-bottom: 0.1rem solid $bc-light; }
.bb-lighter   { border-bottom: 0.1rem solid $bc-lighter; }
.bb-lightest  { border-bottom: 0.1rem solid $bc-lightest; }
.bb-none      { border-bottom: none; }

.bl-dark      { border-left: 0.1rem solid $bc-dark; }
.bl-darker    { border-left: 0.1rem solid $bc-darker; }
.bl-darkest   { border-left: 0.1rem solid $bc-darkest; }
.bl-light     { border-left: 0.1rem solid $bc-light; }
.bl-lighter   { border-left: 0.1rem solid $bc-lighter; }
.bl-lightest  { border-left: 0.1rem solid $bc-lightest; }
.bl-none      { border-left: none; }

@media #{$breakpoint-md} {
  .ba-dark-md      { border: 0.1rem solid $bc-dark; }
  .ba-darker-md    { border: 0.1rem solid $bc-darker; }
  .ba-darkest-md   { border: 0.1rem solid $bc-darkest; }
  .ba-light-md     { border: 0.1rem solid $bc-light; }
  .ba-lighter-md   { border: 0.1rem solid $bc-lighter; }
  .ba-lightest-md  { border: 0.1rem solid $bc-lightest; }
  .ba-none-md      { border: none; }

  .bt-dark-md      { border-top: 0.1rem solid $bc-dark; }
  .bt-darker-md    { border-top: 0.1rem solid $bc-darker; }
  .bt-darkest-md   { border-top: 0.1rem solid $bc-darkest; }
  .bt-light-md     { border-top: 0.1rem solid $bc-light; }
  .bt-lighter-md   { border-top: 0.1rem solid $bc-lighter; }
  .bt-lightest-md  { border-top: 0.1rem solid $bc-lightest; }
  .bt-none-md      { border-top: none; }

  .br-dark-md      { border-right: 0.1rem solid $bc-dark; }
  .br-darker-md    { border-right: 0.1rem solid $bc-darker; }
  .br-darkest-md   { border-right: 0.1rem solid $bc-darkest; }
  .br-light-md     { border-right: 0.1rem solid $bc-light; }
  .br-lighter-md   { border-right: 0.1rem solid $bc-lighter; }
  .br-lightest-md  { border-right: 0.1rem solid $bc-lightest; }
  .br-none-md      { border-right: none; }

  .bb-dark-md      { border-bottom: 0.1rem solid $bc-dark; }
  .bb-darker-md    { border-bottom: 0.1rem solid $bc-darker; }
  .bb-darkest-md   { border-bottom: 0.1rem solid $bc-darkest; }
  .bb-light-md     { border-bottom: 0.1rem solid $bc-light; }
  .bb-lighter-md   { border-bottom: 0.1rem solid $bc-lighter; }
  .bb-lightest-md  { border-bottom: 0.1rem solid $bc-lightest; }
  .bb-none-md      { border-bottom: none; }

  .bl-dark-md      { border-left: 0.1rem solid $bc-dark; }
  .bl-darker-md    { border-left: 0.1rem solid $bc-darker; }
  .bl-darkest-md   { border-left: 0.1rem solid $bc-darkest; }
  .bl-light-md     { border-left: 0.1rem solid $bc-light; }
  .bl-lighter-md   { border-left: 0.1rem solid $bc-lighter; }
  .bl-lightest-md  { border-left: 0.1rem solid $bc-lightest; }
  .bl-none-md      { border-left: none; }
}

@media #{$breakpoint-lg} {
  .ba-dark-lg      { border: 0.1rem solid $bc-dark; }
  .ba-darker-lg    { border: 0.1rem solid $bc-darker; }
  .ba-darkest-lg   { border: 0.1rem solid $bc-darkest; }
  .ba-light-lg     { border: 0.1rem solid $bc-light; }
  .ba-lighter-lg   { border: 0.1rem solid $bc-lighter; }
  .ba-lightest-lg  { border: 0.1rem solid $bc-lightest; }
  .ba-none-lg      { border: none; }

  .bt-dark-lg      { border-top: 0.1rem solid $bc-dark; }
  .bt-darker-lg    { border-top: 0.1rem solid $bc-darker; }
  .bt-darkest-lg   { border-top: 0.1rem solid $bc-darkest; }
  .bt-light-lg     { border-top: 0.1rem solid $bc-light; }
  .bt-lighter-lg   { border-top: 0.1rem solid $bc-lighter; }
  .bt-lightest-lg  { border-top: 0.1rem solid $bc-lightest; }
  .bt-none-lg      { border-top: none; }

  .br-dark-lg      { border-right: 0.1rem solid $bc-dark; }
  .br-darker-lg    { border-right: 0.1rem solid $bc-darker; }
  .br-darkest-lg   { border-right: 0.1rem solid $bc-darkest; }
  .br-light-lg     { border-right: 0.1rem solid $bc-light; }
  .br-lighter-lg   { border-right: 0.1rem solid $bc-lighter; }
  .br-lightest-lg  { border-right: 0.1rem solid $bc-lightest; }
  .br-none-lg      { border-right: none; }

  .bb-dark-lg      { border-bottom: 0.1rem solid $bc-dark; }
  .bb-darker-lg    { border-bottom: 0.1rem solid $bc-darker; }
  .bb-darkest-lg   { border-bottom: 0.1rem solid $bc-darkest; }
  .bb-light-lg     { border-bottom: 0.1rem solid $bc-light; }
  .bb-lighter-lg   { border-bottom: 0.1rem solid $bc-lighter; }
  .bb-lightest-lg  { border-bottom: 0.1rem solid $bc-lightest; }
  .bb-none-lg      { border-bottom: none; }

  .bl-dark-lg      { border-left: 0.1rem solid $bc-dark; }
  .bl-darker-lg    { border-left: 0.1rem solid $bc-darker; }
  .bl-darkest-lg   { border-left: 0.1rem solid $bc-darkest; }
  .bl-light-lg     { border-left: 0.1rem solid $bc-light; }
  .bl-lighter-lg   { border-left: 0.1rem solid $bc-lighter; }
  .bl-lightest-lg  { border-left: 0.1rem solid $bc-lightest; }
  .bl-none-lg      { border-left: none; }
}
