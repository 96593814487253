.w-1024     { width: 102.4rem; }
.w-1280     { width: 128.0rem; }
.w-100      { width: 100%; }
.w-40       { width: 40px; }

.w-full     { width: 100%; }

@media #{$breakpoint-md} {
  .w-1024-md { width: 102.4rem; }
  .w-1280-md { width: 128.0rem; }
  .w-100-md { width: 100%; }
}

@media #{$breakpoint-lg} {
  .w-1024-lg { width: 102.4rem; }
  .w-1280-lg { width: 128.0rem; }
  .w-100-lg  { width: 100%; }
}
